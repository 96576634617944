th.rotate {
    height: 170px;
    position: relative; /* To define the rotated content's position */
}

th.rotate > div {
    width: 170px;
    transform-origin: center center;
    text-align: left;
    white-space: normal;
    line-height: 1.0;
    display: block;
    position: absolute;
    top: 48%;
    left: 50%;
    bottom: 0;
    transform: translate(-50%, -50%) rotate(-90deg);
    margin-left:2.0em;
}

th.sticky {
    position: sticky;
    top: 0;
    z-index: 10;
}

.custom-datepicker-popper {
    z-index: 1050 !important; /* Ensure it has higher priority */
}