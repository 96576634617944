


@import "bootstrap/scss/_functions";
@import "bootstrap/scss/_variables";

$custom-colors: (
        "winfire": #ffeb00
);

$theme-colors : map-merge($custom-colors, $theme-colors);

@import "bootstrap/scss/bootstrap.scss";

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f6f6f6;
  min-width: 230px;
  overflow: auto;
  border: 1px solid #ddd;
  z-index: 5;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown a:hover {background-color: #ddd;}

.dropdown-show {
  display: block;
  z-index: 1000;
}

.table-row-segregation tbody tr {
  border-bottom: 1px solid #000000; /* Horizontal line color */
}
